<template>
  <div class="main-view-padding has-header">
      <div class="header main" v-header-position-lock>
          <div class="align-left">
          </div>
          <div class="align-middle">
                <headerbuttonstack :state="headerButtons"/>
          </div>
          <div class="align-right">
          </div>
      </div>
    <div style="font-size: 32px; text-align: left; color: #e51550; border-bottom:1px solid #ccc; padding: 5px; font-weight:400; margin-bottom:25px;">
      ML Transfer Service Einstellungen
    </div>

    <div v-if="loading" class="loading-md bg" style="z-index: 20">
      <i class="fas fa-spinner"></i>
      <br />
      <div v-if="!$helpers.isEmpty(loadingText)" class="loading-text">
        {{ loadingText }}
      </div>
    </div>

    <div v-if="!loading && !showCompanySettingsOverview" class="scrollbar-container">

      <!-- Einstellungen -->
      <div class="xColumn2">
        <div class="header">Basis Einstellungen</div>

        <!-- internal id -->
        <div class="entryBox">
          <div class="entryHeader">Unternehmen ML ID</div>
          <div class="entryData">
            {{ companyId }}
          </div>
        </div>
        <!-- company name -->
        <div class="entryBox">
          <div class="entryHeader">Unternehmen Name</div>
          <div class="entryData">
            {{ company.name }}
          </div>
        </div>

        <!-- transfer service db settings available -->
        <div class="entryBox">
          <div class="entryHeader">Company Einstellungen vorhanden</div>
          <div v-if="transferServiceCompanySettingsAvailable" class="entryData">
            <i class="fas fa-circle" style="color: green;" /> Angelegt
          </div>
          <div v-else class="entryData">
            <i class="fas fa-circle" style="color: red;" /> Nicht vorhanden
          </div>
          <div v-if="transferServiceCompanySettingsAvailable" class="entrySettings">
          </div>
          <div v-else class="entrySettings">
            <i class="fas fa-plus" v-show="!editing" v-tooltip="'Einstellungen anlegen'" @click="editing = true" />
          </div>
        </div>


        <!------------- regular settings -------------->
        <div v-if="transferServiceCompanySettingsAvailable || editing">

          <!-- company name -->
          <div class="entryBox">
            <div class="entryHeader">API Key</div>
            <div v-if="!editing" class="entryData">
              {{ apiKey }}
            </div>
            <div v-else class="entryData" v-bind:style="apiKey != apiKey_input ? 'color:var(--ml)' : ''">
              {{ apiKey_input }}
            </div>
            <div class="entrySettings">
              <i class="fas fa-rotate-right" @click="generateApiKey()" v-tooltip="'Neuen API Key generieren'" />
              <i v-show="apiKey !== ''" class="fas fa-copy" @click="copyToClipboard(apiKey)" v-tooltip="'API Key in die Zwischenablage kopieren'" />
            </div>
          </div>

          <!-- transfer type -->
          <div class="entryBox">
            <div class="entryHeader">Transfertyp</div>
            <div v-if="saves || !editing" class="entryData">
              {{ getTransferTypesByKey(transferType) }}
            </div>
            <div v-else-if="!saves && editing" class="entryData">
              <select v-model="transferType_input" style="width: 98%">
                <option v-for="item in transferTypes" :value="item.value" :key="item.value">
                  {{ item.label }}
                </option>
              </select>
            </div>
          </div>

          <!-- folders available -->
          <div class="entryBox" @click="showFolderDetails=!showFolderDetails">
            <div class="entryHeader">Import/Export Ordner angelegt</div>
            <div v-if="serverFoldersAvailability == 2" class="entryData">
              <i class="fas fa-circle" style="color: green;" /> Alle angelegt
            </div>
            <div v-else-if="serverFoldersAvailability == 1" class="entryData">
              <i class="fas fa-circle" style="color: orange;" /> Teilweise angelegt
            </div>
            <div v-else-if="serverFoldersAvailability == 0" class="entryData">
              <i class="fas fa-circle" style="color: red;" /> Nicht vorhanden
            </div>
            <div class="entrySettings">
              <i v-show="!showFolderDetails" class="fas fa-caret-down" v-tooltip="'Details anzeigen'" />
              <i v-show="showFolderDetails" class="fas fa-caret-up" v-tooltip="'Details verstecken'" />
              <i v-show="serverFoldersAvailability == 1 && transferServiceCompanySettingsAvailable"
                class="fas fa-folder-plus" @click.stop="createMissingCompanyServerFolders()" v-tooltip="'Fehlende Company Ordner anlegen'"/>
              <i v-show="serverFoldersAvailability == 0 && transferServiceCompanySettingsAvailable"
                class="fas fa-folder-plus"  @click.stop="createMissingCompanyServerFolders()" v-tooltip="'Company Ordner anlegen'"/>
            </div>
          </div>
          <div v-if="showFolderDetails">
            <div class="entryBox" v-for="[key, value] in Object.entries(serverFolders)" v-bind:key="key">
              <div class="entryHeader">&emsp;{{ key }}</div>
              <div v-if="value === true" class="entryData">
                &emsp;
                <i class="fas fa-circle" style="color: green;" /> Angelegt
              </div>
              <div v-else class="entryData">
                &emsp;
                <i class="fas fa-circle" style="color: red;" /> Nicht vorhanden
              </div>
            </div>
          </div>

        </div>

      </div>


      <!------------- server healthcheck settings -------------->
      <div class="xColumn2">
        <div class="header">Server Healthcheck</div>

        <div v-for="[key, value] in Object.entries(processSettings)" v-bind:key="key">
          <div class="entryBox" @click="value.showOutput = !value.showOutput; $forceUpdate();">
            <div class="entryHeader">{{ key }}</div>
            <div v-if="value.running" class="entryData">
              <i class="fas fa-circle" style="color: green;" /> Läuft
            </div>
            <div v-else class="entryData">
              <i class="fas fa-circle" style="color: red;" /> Gestoppt
            </div>
            <div class="entrySettings">
              <i v-show="!value.showOutput" class="fas fa-caret-down" v-tooltip="'Log zeigen'" />
              <i v-show="value.showOutput" class="fas fa-caret-up" v-tooltip="'Log verstecken'" />
              <i class="fas fa-sync" @click.stop="restartService(key)" v-tooltip="'Service neustarten'" />
            </div>
          </div>
          <pre v-if="value.showOutput" style="width: 100%; margin: 0px;">
            {{ value.output }}
          </pre>
        </div>
        <div v-for="[key, value] in Object.entries(supervisorProcessSettings)" v-bind:key="key">
          <div class="entryBox" @click="value.showOutput = !value.showOutput; $forceUpdate();">
            <div class="entryHeader">{{ key }}</div>
            <div v-if="value.running" class="entryData">
              <i class="fas fa-circle" style="color: green;" /> Läuft
            </div>
            <div v-else class="entryData">
              <i class="fas fa-circle" style="color: red;" /> Gestoppt
            </div>
            <div class="entrySettings">
              <i v-show="!value.showOutput" class="fas fa-caret-down" v-tooltip="'Log zeigen'" />
              <i v-show="value.showOutput" class="fas fa-caret-up" v-tooltip="'Log verstecken'" />
              <i v-show="key !== 'supervisorctl'" class="fas fa-sync" @click.stop="restartService(key)" v-tooltip="'Service neustarten'" />
            </div>
          </div>
          <pre v-if="value.showOutput" style="width: 100%; margin: 0px;">
            {{ value.output }}
          </pre>
        </div>
      </div>


      <!------------- editel settings -------------->
      <div class="xColumn2" v-if="showEditelSettings">
        <div class="header">Editel Einstellungen</div>

        <!-- exite link settings available -->
        <!-- <div class="entryBox">
          <div class="entryHeader">eXiteLink Einstellungen angelegt</div>
          <div v-if="exiteLinkSettingsAvailable" class="entryData">
            <i class="fas fa-circle" style="color: green;" /> Angelegt
          </div>
          <div v-else class="entryData">
            <i class="fas fa-circle" style="color: red;" /> Nicht vorhanden
          </div>
          <div v-if="exiteLinkSettingsAvailable" class="entrySettings">
          </div>
          <div v-else class="entrySettings">
            <i class="fas fa-plus" v-show="transferServiceCompanySettingsAvailable" v-tooltip="'Einstellungen anlegen'" />
          </div>
        </div> -->

        <!-- incron table -->
        <div class="entryBox">
          <div class="entryHeader">INCRON Tabelle angelegt</div>
          <div v-if="incronTableFileAvailable" class="entryData">
            <i class="fas fa-circle" style="color: green;" /> Angelegt
          </div>
          <div v-else class="entryData">
            <i class="fas fa-circle" style="color: red;" /> Nicht vorhanden
          </div>
          <div class="entrySettings">
            <i v-if="!incronTableFileAvailable && transferServiceCompanySettingsAvailable" class="fas fa-file-plus" v-tooltip="'Incron Tablle anlegen'" @click="createCompanyIncrontable()" />
            <i v-if="incronTableFileAvailable && transferServiceCompanySettingsAvailable" class="fas fa-trash" v-tooltip="'Incron Tablle löschen'" @click="deleteCompanyIncrontable()" />
          </div>
        </div>
        <div class="entryBox">
          <div class="entryHeader">INCRON Tabelle korrekte Werte</div>
          <div v-if="incronTableDefaultFileContent" class="entryData">
            <i class="fas fa-circle" style="color: green;" /> Stimmt überein
          </div>
          <div v-else class="entryData">
            <i class="fas fa-circle" style="color: red;" /> Stimmt nicht überein
          </div>
          <div class="entrySettings">
            <i v-if="!incronTableDefaultFileContent && incronTableFileAvailable && transferServiceCompanySettingsAvailable" class="fas fa-file-plus" v-tooltip="'Incron Tablle neu anlegen'" @click="createCompanyIncrontable()" />
          </div>
        </div>
      </div>


      <!------------- ftp settings -------------->
      <div class="xColumn2" v-if="showFtpSettings">
        <div class="header">FTP Einstellungen</div>

        <!-- ftp server address -->
        <div class="entryBox">
          <div class="entryHeader">Server Host</div>
          <div v-if="saves || !editing" class="entryData">
            {{ ftpServerHost }}
          </div>
          <div v-else-if="!saves && editing" class="entryData">
            <input v-model="ftpServerHost_input" style="width: 98%"/>
          </div>
          <div class="entrySettings">
            <i v-show="(saves || !editing) && ftpServerHost !== ''" class="fas fa-copy" @click="copyToClipboard(ftpServerHost)" v-tooltip="'Server Host in die Zwischenablage kopieren'" />
          </div>
        </div>
        <div class="entryBox">
          <div class="entryHeader">Server Port</div>
          <div v-if="saves || !editing" class="entryData">
            {{ ftpServerPort }}
          </div>
          <div v-else-if="!saves && editing" class="entryData">
            <input v-model="ftpServerPort_input" style="width: 98%"/>
          </div>
          <div class="entrySettings">
            <i v-show="(saves || !editing) && ftpServerPort !== ''" class="fas fa-copy" @click="copyToClipboard(ftpServerPort)" v-tooltip="'Server Port in die Zwischenablage kopieren'" />
          </div>
        </div>
        <div class="entryBox">
          <div class="entryHeader">Server User</div>
          <div v-if="saves || !editing" class="entryData">
            {{ ftpServerUser }}
          </div>
          <div v-else-if="!saves && editing" class="entryData">
            <input v-model="ftpServerUser_input" style="width: 98%"/>
          </div>
          <div class="entrySettings">
            <i v-show="(saves || !editing) && ftpServerUser !== ''" class="fas fa-copy" @click="copyToClipboard(ftpServerUser)" v-tooltip="'Server User in die Zwischenablage kopieren'" />
          </div>
        </div>
        <div class="entryBox">
          <div class="entryHeader">Server Passwort</div>
          <div v-if="(saves || !editing) && !showPassword" class="entryData">
            &#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;
          </div>
          <div v-else-if="(saves || !editing) && showPassword" class="entryData">
            {{ ftpServerPassword }}
          </div>
          <div v-else-if="!saves && editing" class="entryData">
            <input v-bind:type="!showPassword ? 'password' : ''" v-model="ftpServerPassword_input" style="width: 98%"/>
          </div>
          <div class="entrySettings">
            <i v-show="(saves || !editing) && ftpServerPassword !== ''" class="fas fa-copy" @click="copyToClipboard(ftpServerPassword)" v-tooltip="'Server Passwort in die Zwischenablage kopieren'" />
            <i v-show="!showPassword" class="fas fa-eye" @click="showPassword = true" v-tooltip="'Server Passwort anzeigen'" />
            <i v-show="showPassword" class="fas fa-eye-slash" @click="showPassword = false" v-tooltip="'Server Passwort verstecken'" />
          </div>
        </div>
        <div class="entryBox">
          <div class="entryHeader">Test Login</div>
          <div v-if="ftpServerTestLoginLoading" class="entryData">
            <i class="fas fa-circle" style="color: orange;" /> Login wird getestet...
          </div>
          <div v-else-if="ftpServerTestLogin" class="entryData">
            <i class="fas fa-circle" style="color: green;" /> Login erfolgreich
          </div>
          <div v-else class="entryData">
            <i class="fas fa-circle" style="color: red;" /> Login fehlgeschlagen
          </div>
        </div>
        <div v-if="incronTableFileAvailable" class="entryBox">
          <div class="entryHeader">INCRON Tabelle angelegt</div>
          <div class="entryData">
            <i class="fas fa-circle" style="color: red;" /> Angelegt
          </div>
          <div class="entrySettings">
            <i class="fas fa-trash" @click="deleteCompanyIncrontable()" v-tooltip="'Incron Tabelle löschen'" />
          </div>
        </div>

      </div>

    </div>

    <div v-if="!loading && showCompanySettingsOverview" class="scrollbar-container" style="display:flex; justify-content:center; align-items:flex-start;">
      <table class="table" style="width: 70%;">
        <thead>
        <tr>
          <th scope="col">Company ID</th>
          <th scope="col">Company Name</th>
          <th scope="col">API KEY angelegt</th>
          <th scope="col">Transfertyp</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="settings in companySettingsOverview" :key="'settings_'+settings.companyId" style="line-height: 36px">
          <td>{{ settings.companyId }}</td>
          <td>{{ settings.companyName }}</td>
          <td>
            <i v-if="settings.apiKey" class="fas fa-check" />
            <i v-else class="fas fa-x" />
          </td>
          <td>{{ settings.transferType }}</td>
        </tr>
        </tbody>
      </table>
    </div>



  </div>
</template>

<script>
import Axios from 'axios';
import { mapGetters } from 'vuex';
export default {
 components: {

  },
  name: 'SuperadminTransferService',
  data () {
    return {
      company: {},

      loading: true,
      loadingText: "Daten werden geladen...",
      saves: false,
      editing: false,
      showCompanySettingsOverview: false,
      companySettingsOverview: {},

      transferServiceCompanySettingsAvailable: false,

      companyNameBackend: "",
      apiKey: "",
      apiKey_input: "",
      transferType: "",
      transferType_input: "",
      transferTypes: [
        { value: "EDITEL", label: "Editel", backendFolderName: "editel" },
        { value: "FTP", label: "FTP", backendFolderName: "ftp" },
      ],

      serverFolders: {},
      showFolderDetails: false,
      incronTableFileAvailable: false,
      incronTableDefaultFileContent: false,

      // healthcheck settings
      processSettings: {},
      supervisorProcessSettings: {},

      // editel settings
      exiteLinkSettingsAvailable: false,

      // ftp settings
      ftpServerHost: "",
      ftpServerHost_input: "",
      ftpServerPort: 0,
      ftpServerPort_input: 0,
      ftpServerUser: "",
      ftpServerUser_input: "",
      ftpServerPassword: "",
      ftpServerPassword_input: "",
      showPassword: false,
      ftpServerTestLogin: false,
      ftpServerTestLoginLoading: false,
    }
  },
  created: function () {
  },
  mounted: function () {
    if(this.$store.state.jwt != undefined && this.companyId != undefined){
        this.refresh();
    }
  },
  computed: {
    ...mapGetters([
      'companyId',
      'isSuperUser',
      'companySettings',
    ]),
    headerButtons() {
      let view = this;
      let state = {
        buttons: [
          {
            type: "block border-r",
            icon: "fa-refresh",
            click: () => {
              view.refresh();
            },
            show: !view.saves && !view.editing,
            disabled: view.loading || view.saves,
            tooltip: "Daten neu laden",
            title: "Daten neu laden"
          },
          {
            type: "block",
            icon: "fa-edit",
            show: !view.saves && !view.editing && !view.showCompanySettingsOverview,
            disabled: view.loading || view.saves,
            click: () => {
              view.editing = !view.editing;
            },
            tooltip: "Bearbeiten",
            title: "Bearbeiten",
          },
          {
            type: "block red",
            icon: "fa-ban",
            show: !view.saves && view.editing,
            click: () => {
              view.resetInputs();
              view.editing = !view.editing;
            },
            tooltip: "Abbrechen",
          },
          {
            type: "block green",
            icon: "fa-save",
            show: !view.saves && view.editing,
            click: () => {
              view.saveMLTransferCompanySettings();
            },
            tooltip: "Änderung speichern",
          },
          {
            show: view.saves,
            type: "block",
            icon: "fa-spinner fa-spin",
            click: () => {
            },
            tooltip: "wird gespeichert",
          },
          {
            show: !view.saves && !view.editing && !view.showCompanySettingsOverview,
            type: "block border-l",
            icon: "fa-magnifying-glass",
            disabled: view.loading || view.saves || !view.companySettingsOverview || Object.values(view.companySettingsOverview).length === 0,
            click: () => {
              view.showCompanySettingsOverview = true;
            },
            tooltip: "Company Übersicht",
          },
          {
            show:  view.showCompanySettingsOverview,
            type: "block",
            icon: "fa-chevron-left",
            click: () => {
              view.showCompanySettingsOverview = false;
            },
            tooltip: "Zurück zu den Einstellungen",
          },
        ]
      };
        return state;
    },
    serverFoldersAvailability() {
      let view = this;
      if (Object.values(view.serverFolders).length === 0) {
        return 0;
      }

      let transferType = view.transferTypes.find(item => item.value === view.transferType);
      let backendFolderName = transferType ? transferType.backendFolderName : "editel";
      let countFalse = 0;
      let count = 0;
      Object.entries(view.serverFolders)
        .filter(([key, value]) => /* key.startsWith("mlt") || */ key.startsWith(backendFolderName))
        .forEach(([key, value]) => {
          if (value === false) {
            countFalse++;
          }
          count++;
        });
      return countFalse == count ? 0 : countFalse == 0 ? 2 : 1;
    },
    showEditelSettings() {
      let view = this;
      return (!view.editing && view.transferType == "EDITEL") || (view.editing && view.transferType_input == "EDITEL");
    },
    showFtpSettings() {
      let view = this;
      return (!view.editing && view.transferType == "FTP") || (view.editing && view.transferType_input == "FTP");
    },
  },
  watch: {
    companyId: function (newVal, oldVal) {
      this.refresh();
    },
  },
  // methods that implement data logic.
  methods: {
    refresh: function(force = false){
        let view = this;
        view.loading = true;
        view.showCompanySettingsOverview = false;
        view.companySettingsOverview = {};
        view.showPassword = false;
        view.ftpServerTestLoginLoading = true;

        let promises = [];
        promises.push(view.$helpers.GetCompanySettings(force)
        .then((response) => {
          view.company = response.data[0];
        }));
        promises.push(view.getMLTransferCompanySettings()
        .then((response) => {
          if (!response) {
            view.cleanInputs();
          } else {
            view.transferServiceCompanySettingsAvailable = true;
            view.apiKey = response.companySettings.apiKey;
            view.apiKey_input = response.companySettings.apiKey;
            view.transferType = response.companySettings.transferType;
            view.transferType_input = response.companySettings.transferType;
            view.ftpServerHost = response.companySettings.ftpServerHost;
            view.ftpServerHost_input = response.companySettings.ftpServerHost;
            view.ftpServerPort = response.companySettings.ftpServerPort;
            view.ftpServerPort_input = response.companySettings.ftpServerPort;
            view.ftpServerUser = response.companySettings.ftpServerUser;
            view.ftpServerUser_input = response.companySettings.ftpServerUser;
            view.ftpServerPassword = response.companySettings.ftpServerPassword;
            view.ftpServerPassword_input = response.companySettings.ftpServerPassword;
            view.companyNameBackend = response.companySettings.companyName;

            view.serverFolders = response.folderSettings;

            view.incronTableFileAvailable = response.incronTableSettings.fileAvailable;
            view.incronTableDefaultFileContent = response.incronTableSettings.defaultFileContent;

            view.ftpServerTestLogin = response.ftpTestLoginSettings.loginSuccessful;
            view.ftpServerTestLoginLoading = false;

            view.processSettings = response.processSettings || {};
            view.supervisorProcessSettings = response.supervisorProcessSettings || {};
          }
        })
        .then(() => {
          view.getMLTransferCompanySettingsOverview()
          .then((response) => {
            if (response) {
              view.companySettingsOverview = response;
            }
          });
        }));
        Promise.all(promises).then(() => {
          view.loading = false;
        });
    },



    // proxy routes
    getMLTransferCompanySettingsOverview: function(){
      let view = this;
      return view.$helpers.GetCall("/api/sec/proxy/mltransfer/companySettings")
        .then((response) => {
          let map = response.data;
          return new Promise((resolve, reject) => {
            resolve(view.$helpers.returnObj(map, false));
          });
        })
        .catch((e) => {
          console.log(e);
          return new Promise((resolve, reject) => {
            resolve(null);
          });
        });
    },
    getMLTransferCompanySettings: function(){
      let view = this;
      return view.$helpers.GetCall("/api/sec/proxy/mltransfer/companySettings/" + view.companyId)
        .then((response) => {
          let map = response.data;
          return new Promise((resolve, reject) => {
            resolve(view.$helpers.returnObj(map, false));
          });
        })
        .catch((e) => {
          console.log(e);
          return new Promise((resolve, reject) => {
            resolve(null);
          });
        });
    },
    saveMLTransferCompanySettings() {
      let view = this;

      view.saves = true;
      view.editing = false;
      let mode = view.transferServiceCompanySettingsAvailable == true ? "update" : "create";

      let payload = {};
      if (view.company.name !== view.companyNameBackend) {
        payload.companyName = view.company.name;
      }
      if (view.apiKey != view.apiKey_input) {
        payload.apiKey = view.apiKey_input;
      }
      if (view.transferType != view.transferType_input) {
        payload.transferType = view.transferType_input;
      }
      if (view.ftpServerHost != view.ftpServerHost_input) {
        payload.ftpServerHost = view.ftpServerHost_input;
      }
      if (view.ftpServerPort != view.ftpServerPort_input) {
        payload.ftpServerPort = view.ftpServerPort_input;
      }
      if (view.ftpServerUser != view.ftpServerUser_input) {
        payload.ftpServerUser = view.ftpServerUser_input;
      }
      if (view.ftpServerPassword != view.ftpServerPassword_input) {
        payload.ftpServerPassword = view.ftpServerPassword_input;
      }

      let successCallback = (response) => {
        view.saveInputs();
        if (!view.transferServiceCompanySettingsAvailable || Object.keys(payload).find(key => key == "transferType")) {
          this.refresh();
        }
        view.transferServiceCompanySettingsAvailable = true;
        if (Object.keys(payload).find(key => key.startsWith("ftp"))) {
          view.getMLTransferFtpTestLogin();
        }
        this.saves = false;
      }
      let errorCallback = (e) => {
        view.resetInputs();
        this.saves = false;
      }

      if (Object.keys(payload).length === 0) {
        view.saves = false;
        return;
      }

      let promise = null;
      if (mode === "create") {
        promise = view.$helpers.CreateCall("/api/sec/proxy/mltransfer/companySettings", payload, successCallback, null, errorCallback);
      } else {
        promise = view.$helpers.EditCall("/api/sec/proxy/mltransfer/companySettings/" + view.companyId, payload, successCallback, null, errorCallback);
      }
      return promise;
    },
    getMLTransferFtpTestLogin() {
      let view = this;
      view.ftpServerTestLoginLoading = true;
      return view.$helpers.GetCall("/api/sec/proxy/mltransfer/companySettings/ftp/testlogin")
        .then((response) => {
          console.log(response);
          if (response.data.loginSuccessful) {
            view.ftpServerTestLogin = true;
          } else {
            view.ftpServerTestLogin = false;
          }
          view.ftpServerTestLoginLoading = false;
        })
        .catch((e) => {
          console.log(e);
          view.ftpServerTestLogin = false;
          view.ftpServerTestLoginLoading = false;
        });
    },
    createMissingCompanyServerFolders() {
      let view = this;

      let transferType = view.transferTypes.find(item => item.value === view.transferType);
      let backendFolderName = transferType ? transferType.backendFolderName : "editel";

      let folderArray = Object.entries(view.serverFolders)
        .filter(([key, value]) => key.startsWith(backendFolderName) && value === false)
        .map(([key, value]) => key);

      let payload = {
        folderArray: folderArray,
      };

      view.$helpers.CreateCall("/api/sec/proxy/mltransfer/companySettings/folders", payload, (response) => {
        folderArray.forEach(folder => {
          view.serverFolders[folder] = true;
        });
      }, null, (e) => {
        console.log(e);
        view.refresh();
      });
    },
    createCompanyIncrontable() {
      let view = this;

      let transferType = view.transferTypes.find(item => item.value === view.transferType);

      let payload = {
        "companyName": view.company.name,
        "transferType": transferType ? transferType.value : "EDITEL",
      };

      view.$helpers.CreateCall("/api/sec/proxy/mltransfer/companySettings/incrontable", payload, (response) => {
        view.incronTableFileAvailable = true;
        view.incronTableDefaultFileContent = true;
      }, null, (e) => {
        console.log(e);
        view.refresh();
      });
    },
    deleteCompanyIncrontable() {
      let view = this;

      let url = '/api/sec/proxy/mltransfer/companySettings/incrontable';
      let params = {
        "companyId": view.companyId,
        "companyName": view.company.name,
      };

      return view.axios({
          method: 'delete',
          params: params,
          headers: {
              'AUTHORIZATION': "Bearer " + view.$store.state.jwt
          },
          url: url,
      }).then(response => {
          view.incronTableFileAvailable = false;
          view.incronTableDefaultFileContent = false;
          view.$helpers.success('Erfolg', 'Incron Tabelle gelöscht');
      }).catch(error => {
        console.log(error);
        view.$helpers.error('Fehler', 'Fehler beim Löschen der Incron Tabelle');
        view.refresh();
      });
    },
    restartService(serviceName) {
      let view = this;

      let url = "/api/sec/proxy/mltransfer/companySettings/services/restart";
      let params = {
      };
      let payload = {
        "companyId": view.companyId,
        "serviceName": serviceName,
      };

      return view.axios({
          method: 'post',
          // params: params,
          data: payload,
          headers: {
              'AUTHORIZATION': "Bearer " + view.$store.state.jwt
          },
          url: url,
      }).then(response => {
        if (response.data && response.data.data && response.data.data.processRestarted) {
          view.$helpers.success('Erfolg', 'Service neugestartet');
        } else {
          console.log(response.data);
          view.$helpers.error('Fehler', 'Fehler beim Neustarten des Services');
        }
      }).catch(error => {
        console.log(error);
        view.$helpers.error('Fehler', 'Fehler beim Neustarten des Services');
        view.refresh();
      });
    },


    // helper
    getTransferTypesByKey(key) {
      let view = this;
      let label = "";
      let type = view.transferTypes.find(item => item.value === key);
      if (type) {
        label = type.label;
      }
      return label;
    },
    copyToClipboard(textToCopy) {
      // Copy the text inside the text field
      navigator.clipboard.writeText(textToCopy);

      console.log("Copied the text: " + textToCopy);
      this.$helpers.info(
          "Info",
          "Text wurde in die Zwischenablage kopiert."
      );
    },
    generateApiKey() {
      let view = this;

      let length = 32;

      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      this.apiKey_input = result;
      this.editing = true;
    },
    cleanInputs() {
      let view = this;
      view.transferServiceCompanySettingsAvailable = false;
      view.companyNameBackend = "";
      view.apiKey = "";
      view.apiKey_input = "";
      view.transferType = "";
      view.transferType_input = "";
      view.ftpServerHost = "";
      view.ftpServerHost_input = "";
      view.ftpServerPort = 0;
      view.ftpServerPort_input = 0;
      view.ftpServerUser = "";
      view.ftpServerUser_input = "";
      view.ftpServerPassword = "";
      view.ftpServerPassword_input = "";
      view.serverFolders = {};
      view.incronTableFileAvailable = false;
      view.incronTableDefaultFileContent = false;
      view.ftpServerTestLogin = false;
      view.ftpServerTestLoginLoading = false;
    },
    resetInputs() {
      let view = this;

      view.apiKey_input = view.apiKey;
      view.transferType_input = view.transferType;
      view.ftpServerHost_input = view.ftpServerHost;
      view.ftpServerPort_input = view.ftpServerPort;
      view.ftpServerUser_input = view.ftpServerUser;
      view.ftpServerPassword_input = view.ftpServerPassword;
    },
    saveInputs() {
      let view = this;

      view.apiKey = view.apiKey_input;
      view.transferType = view.transferType_input;
      view.ftpServerHost = view.ftpServerHost_input;
      view.ftpServerPort = view.ftpServerPort_input;
      view.ftpServerUser = view.ftpServerUser_input;
      view.ftpServerPassword = view.ftpServerPassword_input;
    }

  },
}
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped>
  .scrollbar-container {
    height: calc(100vh - 260px);
    width: calc(100% - 20px);
  }

  .xColumn2 {
    width: 48%;
    text-align: left;
    float: left;
    margin-left: 1%;
    margin-right: 1%;
    overflow-y: auto;
    padding-bottom: 20px;
  }

  .xColumn2 > .header {
    display: inline-block;
    width: 100%;
    color: #e51550;
    font-size: 15px;
    font-weight: 700;
    border-bottom: 1px solid #ccc;
    text-align: left;
    padding: 7px;
  }

  .entryBox {
    font-size: 13px;
    text-align: left;
    padding: 14px 7px;
  }

  .entryBox .entryHeader {
    display: inline-block;
    width: 45%;
    float: left;
    color: #555;
    font-weight: 700;
  }

  .entryBox .entryHeaderLight {
    display: inline-block;
    width: 45%;
    float: left;
    color: #aaa;
    font-weight: 200;
  }

  .entryBox .entryData {
    display: inline-block;
    width: 45%;
    float: left;
    color: #aaa;
    font-weight: 200;
  }

  .entryBox .entrySettings {
    display: inline-block;
    width: 9%;
    float: right;
    color: #aaa;
    font-weight: 200;
  }

  .entryBox .entrySettings i {
    cursor: pointer;
    padding-left: 5px;
  }

</style>
